import { useState } from "react";
import { Drawer } from "vaul";

import Content from "../content/Content";

const Notification = ({
  notification,
  notificationActive,
  setNotification,
}) => {
  const { text, color } = notification || {};

  console.log({ notification });

  return (
    <>
      <article className="Notification" onClick={() => setNotification(null)}>
        <Drawer.Root
          open={!!notificationActive}
          onClose={() => setNotification(null)}
        >
          <Drawer.Portal>
            <Drawer.Content className="NotificationContent">
              <section
                className="NotificationInner"
                style={{ "--bg": color?.hex }}
              >
                <div className="decorators">
                  <div className="decorator" key={0} />
                  <div className="decorator" key={1} />
                  <div className="decorator" key={2} />
                  <div className="decorator" key={3} />
                </div>
                <Content blocks={text} />
              </section>
            </Drawer.Content>
          </Drawer.Portal>
          <Drawer.Overlay className="NotificationBackground" />
        </Drawer.Root>
      </article>
      <style jsx global>{`
        [vaul-drawer] {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 2;
          pointer-events: none;
        }

        .NotificationContent {
          display: flex;
          justify-content: center;
          align-items: center;
          pointer-events: none;
        }

        .NotificationInner {
          display: block;
          width: min((33vh * 2), (100% - 2 * var(--dist-sm)));
          aspect-ratio: 2 / 1;
          overflow: auto;
          -ms-overflow-style: none;
          scrollbar-width: none;
          position: relative;
          background: var(--bg);
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          border-radius: 1px;
          padding: var(--lh-md) max(8%, 5ch);
          font-family: "Antique-Legacy";
          font-weigth: 450;
          font-size: calc(var(--fs-sm) / 1.2);
          line-height: var(--lh-sm);
          text-transform: uppercase;
          letter-spacing: calc((var(--fs-sm) / 1.2) * 0.12);
          pointer-events: none;
        }

        .NotificationInner .decorators {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          padding: var(--dist-sm);
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-template-rows: 1fr 1fr;
        }

        .NotificationInner .decorator {
          width: 5px;
          height: 5px;
          position: relative;
        }

        .NotificationInner .decorator::after {
          width: 1px;
          height: 5px;
          content: "";
          position: absolute;
          top: 0;
          left: 2px;
          background: black;
        }

        .NotificationInner .decorator:nth-child(1)::before,
        .NotificationInner .decorator:nth-child(2)::before {
          width: 5px;
          height: 1px;
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          background: black;
        }

        .NotificationInner .decorator:nth-child(3)::before,
        .NotificationInner .decorator:nth-child(4)::before {
          width: 5px;
          height: 1px;
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          background: black;
        }

        .NotificationInner .decorator:nth-child(1) {
          align-self: start;
          justify-self: start;
        }

        .NotificationInner .decorator:nth-child(2) {
          align-self: start;
          justify-self: end;
        }

        .NotificationInner .decorator:nth-child(3) {
          align-self: end;
          justify-self: start;
        }

        .NotificationInner .decorator:nth-child(4) {
          align-self: end;
          justify-self: end;
        }

        .NotificationBackground {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          backdrop-filter: blur(1px);
          background: hsla(0 0% 100% / 0.9);
          z-index: 1;
          pointer-events: auto;
        }
      `}</style>
    </>
  );
};

export default Notification;
