import { PortableText } from "@portabletext/react";

import components from "../components";

const BlockLine = ({ value }) => {
  return (
    <div className="BlockLine">
      <div className={"line visible"}>
        <div />
      </div>
      <PortableText value={value.content} components={components} />

      <style jsx global>{`
        .BlockLine {
          position: relative;
          padding-left: 10px;

          .line {
            height: 100% !important;
            position: absolute;
            left: -3px;
          }

          .line div {
            border-left: 1px solid black;
            height: 100%;*
          }
        }
        
      `}</style>
    </div>
  );
};

export default BlockLine;
