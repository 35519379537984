import { AnimatePresence, motion } from "framer-motion";

import Footer from "./Footer";
import Header from "./Header";
import Metadata from "./Metadata";
import ComingSoon from "./ComingSoon";

const Body = ({
  children,
  seo,
  data,
  _translations,
  navigation,
  cart,
  generalInfo,
  links,
  notificationActive,
  ...props
}) => {
  const isComingSoon = process.env.NEXT_PUBLIC_IS_WIP === "true";

  console.log({ isComingSoon });

  return (
    <>
      <Metadata
        defaultSeo={seo?.defaultSeo}
        seo={data?.page?.seo}
        title={data?.page?.title}
      />

      {!isComingSoon && (
        <Header
          navigation={navigation}
          translations={_translations}
          cart={cart}
          notificationActive={notificationActive}
        />
      )}
      {children}

      {!isComingSoon && <Footer generalInfo={generalInfo} links={links} />}

      <style jsx global>
        {""}
      </style>
    </>
  );
};

export default Body;
