import Link from "next/link";
import { useRouter } from "next/router";

import { getFileUrl } from "@/lib/utils";

const Hyperlink = ({ children, ...props }) => {
  const { locale, asPath } = useRouter();

  if (!props?.link && !children) return null;

  const { href, text, type, file, blank, reference } = props?.link;

  const link = {
    external: { title: href, href },
    internal: {
      title: reference?.title,
      href:
        [
          ["page"].includes(reference?._type) ? null : reference?._type,
          reference?.slug?.current,
        ]
          ?.filter(Boolean)
          ?.join("/") || "",
    },
    file: { title: file, href: getFileUrl(file?.asset?._ref) },
    function: { href: "?newsletter=1" },

    tel: { title: href, href },
    email: { title: href, href },
  };

  let typeParsed;

  if (type !== "external") {
    typeParsed = type;
  } else {
    if (!["mailto:", "tel:"].some((prefix) => href.startsWith(prefix))) {
      typeParsed = type;
    } else if (href.startsWith("tel:")) {
      typeParsed = "tel";
    } else {
      typeParsed = "email";
    }
  }

  return (
    <>
      {/*eslint-disable */}
      {typeParsed === "external" ? (
        <a
          href={link?.[typeParsed]?.href}
          target={typeParsed === "external" && blank ? "_blank" : "_self"}
          rel={
            typeParsed === "external" && blank
              ? "noopener noreferrer"
              : undefined
          }
        >
          {children || text?.[locale] || text || link?.[typeParsed]?.title}
        </a>
      ) : (
        <Link
          href={
            (type === "internal"
              ? "/"
              : type === "function"
                ? asPath + "/"
                : "") + link?.[typeParsed]?.href
          }
        >
          {children || text?.[locale] || text || link?.[typeParsed]?.title}
        </Link>
      )}
      {/*eslint-enable */}

      <style jsx global>
        {""}
      </style>
    </>
  );
};

export default Hyperlink;
