
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import "../global.css";
import { AnimatePresence, motion } from "framer-motion";
import { Router, useRouter } from "next/router";
import Script from "next/script";
import { groq } from "next-sanity";
import { lazy, useEffect, useState } from "react";
import Body from "@/components/_app/Body";
import Notification from "@/components/shared/Notification";
import { SUPPORTED_LANGUAGES } from "@/config";
import useScrollbarWidth from "@/hooks/useScrollbarWidth";
import { getClient } from "../lib/sanity.client";
import ComingSoon from "@/components/_app/ComingSoon";
const PreviewProvider = lazy(() => import("@/components/preview/PreviewProvider"));
function App({ Component, pageProps, globalProps }) {
    const { draftMode, token } = pageProps || {};
    // Set scrollbar width as variable if present
    useScrollbarWidth();
    // For page transition (TEST)
    const [isTransition, setTransition] = useState(false);
    useEffect(() => {
        const start = () => {
            setTransition(true);
        };
        const end = () => {
            if (typeof window !== undefined)
                window?.scrollTo({ top: 0 });
            setTimeout(() => setTransition(false), 500);
        };
        Router.events.on("routeChangeStart", start);
        Router.events.on("routeChangeComplete", end);
        Router.events.on("routeChangeError", end);
        return () => {
            Router.events.off("routeChangeStart", start);
            Router.events.off("routeChangeComplete", end);
            Router.events.off("routeChangeError", end);
        };
    }, []);
    console.log({ globalProps });
    const [notificationActive, setNotification] = useState(!!globalProps?.notification);
    const isComingSoon = process.env.NEXT_PUBLIC_IS_WIP === "true";
    return (<>
      <Body {...pageProps} {...globalProps} notificationActive={notificationActive}>
        <Notification notification={globalProps?.notification} {...{ notificationActive, setNotification }}/>

        {!isComingSoon ? (<AnimatePresence mode="wait">
            <motion.main className={["Route"].join(" ")} initial={{ opacity: 0 }} animate={{
                opacity: 1,
            }} exit={{ opacity: 0 }} transition={{ duration: 0.4 }} key={pageProps?.page?._id}>
              {draftMode ? (<PreviewProvider token={token}>
                  <Component {...pageProps}/>
                </PreviewProvider>) : (<Component {...pageProps}/>)}
            </motion.main>
          </AnimatePresence>) : (<ComingSoon notification={globalProps?.notification} {...{ notificationActive, setNotification }}/>)}
      </Body>

      <Script id="scrollRestoration">
        {'window.history.scrollRestoration = "manual"'}
      </Script>
    </>);
}
App.getInitialProps = async (ctx) => {
    const { draftMode = false, params } = ctx;
    const client = getClient();
    const [globalProps] = await Promise.all([
        client.fetch(groq `{${[
            queries?.metadata,
            queries?.notification,
            queries?.navigation,
            queries?.contact,
        ]
            .filter(Boolean)
            .join(",")}}`, { ...params /* locale, slug, sub */ }),
    ]);
    return {
        globalProps,
    };
};
const queries = {
    metadata: `"metadata": *[_type == "metadata"][0]`,
    notification: `"notification": *[_type == "notification"][0]`,
    navigation: `"navigation": *[_type == "navigation"][0]{..., links[]{..., reference->}}`,
    contact: `"contact": *[_type == "contact"][0]`,
};

    export default __appWithI18n(App, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
    });
  