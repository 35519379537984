import { PortableText } from "@portabletext/react";

import components from "./components";

const Content = ({ blocks, full, children }) => {
  if (!children && (!blocks || blocks.length === 0)) return null;

  return (
    <>
      <div className="Content" data-full={full}>
        <PortableText value={blocks} components={{ ...components }} />
        {children}
      </div>

      <style jsx global>{`
        .Content {
          width: 100%;
          position: relative;
        }

        .Content[data-full="true"] {
          .highlight {
            background: var(--color-yellow);
            box-decoration-break: clone;
            padding: 0 0.25ch;
            margin: 0 -0.25ch;
          }

          > :is([class*=" BlockHeadline"], [class^="BlockHeadline"]),
          > :is([class*=" BlockText"], [class^="BlockText"]) {
            display: block;
          }

          > :is([class*=" Block"], [class^="Block"]):not(:is(.BlockHeadline))
            + :is([class*=" Block"], [class^="Block"]):not(:is(.BlockText)) {
            padding-top: calc(var(--dist-5) + var(--dist-7));
          }

          > :is([class*=" Block"], [class^="Block"]):not(:is(.BlockHeadline))
            + :is(.BlockText) {
            padding-top: calc(var(--dist-5) + var(--dist-7));
          }

          > :is([class*=" Block"], [class^="Block"]):not(:is(.BlockHeadline))
            + :is(.BlockHeadline) {
            padding-top: calc(var(--dist-5) + var(--dist-7));
          }
        }
      `}</style>
    </>
  );
};

export default Content;
