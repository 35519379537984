import Link from "next/link";

import BlockLine from "./blocks/BlockLine";
import BlockTable from "./blocks/BlockTable";
import BlockText from "./blocks/BlockText";

const components = {
  undefined: (props) => {
    return null;
  },

  block: {
    normal: ({ children }) => <BlockText type="normal">{children}</BlockText>,
    headline: ({ children }) => <h4>{children}</h4>,
  },

  marks: {
    uppercase: (props) => (
      <span style={{ textTransform: "uppercase" }}>{props?.children}</span>
    ),
    highlight: (props) => <span className="highlight">{props?.children}</span>,
    large: (props) => <span className="large">{props?.children}</span>,
    em: ({ children }) => <em>{children}</em>,

    link: ({ value, children }) => {
      const { blank, href, type, reference } = value;

      return !(children || text) ? null : type === "external" ? (
        <a
          href={href}
          target={blank ? "_blank" : "_self"}
          rel={blank ? "noopener noreferrer" : undefined}
        >
          {children || text}
        </a>
      ) : (
        <Link href={reference?.slug?.current || ""}>{children || text}</Link>
      );
    },

    // annotation: (props) => <MarkFootnote {...props} />,
  },

  types: {
    blockTable: ({ value }) => <BlockTable value={value} />,
    blockLine: ({ value }) => <BlockLine value={value} />,
    undefined: (props) => {
      return null;
    },
  },
};

export default components;
