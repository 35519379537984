import { motion } from "framer-motion";
import useTranslation from "next-translate/useTranslation";

const Footer = ({ generalInfo, links }) => {
  const { t } = useTranslation("common");

  const { address, phone, email, mapLink } = generalInfo || {};

  return (
    <>
      <motion.footer
        className="Footer"
        initial={{ opacity: 0 }}
        exit={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{}}
      ></motion.footer>
      <style jsx global>{``}</style>
    </>
  );
};

export default Footer;
