import Content from "../content/Content";
import Logo from "../shared/Logo";

const ComingSoon = ({ notification, notificationActive, setNotification }) => {
  return (
    <>
      <div
        className="ComingSoon"
        onClick={() => setNotification(!notificationActive)}
      >
        <section className="intro">
          <Content blocks={notification?.comingSoon} />
        </section>
        <Logo />
      </div>

      <style jsx global>{`
        [vaul-drawer]:not(:active) {
          transition: transform 1s !important;
        }

        .ComingSoon {
          --w-decorator: 5px;
          --gap: calc(var(--dist-sm) * 2 - var(--w-decorator));
          display: flex;
          justify-content: flex-end;
          flex-direction: column;
          padding: var(--dist-sm);
          height: calc(100 * var(--svh));
          font-size: calc(var(--fs-sm) / 1.2); /* Compensate for uppercase */
          text-transform: uppercase;
          letter-spacing: 0.12em;
          gap: var(--gap);
        }

        .ComingSoon .intro {
          position: fixed;
          top: 0;
          left: 0;
          z-index: 10;
          padding: var(--dist-sm);
        }

        .ComingSoon .Logo {
        }
      `}</style>
    </>
  );
};

export default ComingSoon;
