const BlockTable = ({ value }) => {
  if (!value || !value.rows || !Array.isArray(value.rows)) {
    return null;
  }

  return (
    <table>
      <tbody>
        {value.rows.map((row, rowIndex) => (
          <tr key={row._key || rowIndex}>
            {row.cells.map((cell, cellIndex) => (
              <td key={cellIndex}>{cell}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default BlockTable;
