import { motion } from "framer-motion";
import Link from "next/link";
import { useRouter } from "next/router";
import useMeasure from "react-use-measure";

import Hyperlink from "../shared/Hyperlink";
import Logo from "../shared/Logo";

const Header = ({ navigation, notificationActive }) => {
  const router = useRouter();
  const { mode } = router?.query || {};

  const [ref, bounds] = useMeasure();

  return (
    <>
      <motion.header
        className="Header"
        initial={{ opacity: 0 }}
        exit={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{}}
        ref={ref}
      >
        <Link href="/" aria-label="Homepage">
          <Logo />
          <span className="visually-hidden">Frauen Bauen</span>
        </Link>
        <motion.nav
          initial={{ opacity: 0 }}
          exit={{ opacity: 0 }}
          animate={{ opacity: notificationActive ? 0 : 1 }}
        >
          <section>
            <Link href="/?mode=map" data-active={mode !== "index"}>
              Karte
            </Link>
            <Link href="/?mode=index" data-active={mode === "index"}>
              Index
            </Link>
          </section>
          {navigation?.links?.map((link, i) => (
            <Hyperlink link={link} key={i} />
          ))}
        </motion.nav>
      </motion.header>

      <style jsx global>{`
        :root {
          --h-header: ${bounds?.height}px;
        }
      `}</style>

      <style jsx global>{`
        .Header {
          position: sticky;
          left: 0;
          top: 0;
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center;
          gap: 1em;
          padding: var(--dist-sm);
          z-index: 99;
        }

        .Header h1 img {
          width: auto;
          height: var(--size-md);
        }

        .Header nav {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          gap: var(--size-md);
        }

        .Header nav section {
          display: flex;
          flex-wrap: wrap;
        }

        .Header nav section a {
          display: block;
          width: auto;
          height: var(--size-sm);
          line-height: var(--size-sm);
          white-space: nowrap;
          padding: 0 1em;
          background: hsl(0, 0%, 90%);
          border-radius: 2px;
          font-size: calc(var(--fs-sm) / 1.2); /* Compensate for uppercase */
          text-transform: uppercase;
          letter-spacing: 0.12em;
          color: var(--color-black);
        }

        .Header nav section a[data-active="true"] {
          background: var(--color-black);
          color: var(--color-white);
        }
      `}</style>
    </>
  );
};

export default Header;
