export const TITLE = "Frauen Bauen";
export const URL = "http://localhost:3000/";
export const CREATED_AT = "2024-05-28";

export const SUPPORTED_LANGUAGES = [
  { id: "de", title: "Deutsch", icon: "🇩🇪", isDefault: true },
];

export const MULTILINGUAL = SUPPORTED_LANGUAGES?.length > 1;

export const BASE_LANGUAGE =
  SUPPORTED_LANGUAGES.find((l) => l.isDefault) || SUPPORTED_LANGUAGES?.[0];

// Specify Platform for hosting, deployment, etc.
export const PLATFORM = ["netlify"];

export const PREVIEW_BASE_URL = "/api/draft";

export const SINGLETONS = ["options", "metadata", "contact"];
export const COLLECTIONS = ["page", "news", "project", "architect"];

export const PAGES = [...COLLECTIONS];

// Document types which:
// - cannot be created in the 'new document' menu
// - cannot be duplicated, unpublished or deleted
export const LOCKED_DOCUMENT_TYPES = [
  ...SINGLETONS,
  "media.tag",
  "translation.metadata",
];

// References to include in 'internal' links
export const PAGE_REFERENCES = PAGES.map((type) => ({
  type,
}));

export const PREVIEWABLE_DOCUMENT_TYPES = PAGES;
