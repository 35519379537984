import { isEmpty } from "lodash";

const BlockText = ({ as: P = "p", type, children }) => {
  return isEmpty(children) ? null : (
    <>
      <P className="BlockText" data-type={type}>
        {children}
      </P>

      <style jsx global>{`
        .Content[data-full="true"] {
          & > .BlockText {
            padding: 0 var(--dist-1);
          }

          & > .BlockText + .BlockText {
            padding-top: calc(var(--dist-5));
          }

          & > .BlockHeadline.h1 + .BlockText {
            padding-top: calc(var(--dist-7));
          }

          & > .BlockHeadline + .BlockText {
            padding-top: calc(var(--dist-5));
          }

          & > .BlockHeadline + .BlockText[data-type="small"] {
            padding-top: calc(var(--dist-3));
          }

          & > .BlockText[data-type="normal"] {
            font-size: var(--fs-sm);
            line-height: var(--lh-sm);
            font-family: "Eliza-Regular";
          }
        }

        .BlockText[data-type="small"] {
          font-size: var(--fs-xs);
          line-height: var(--lh-xs);
          font-family: "ABCROMMono-Regular";
        }

        .BlockText[data-type="large"] {
          font-size: var(--fs-md);
          line-height: var(--lh-md);
        }

        @media (min-width: 1024px) {
          .Content[data-full="true"] {
            & > .BlockText[data-type="normal"] {
              {/* columns: 2;
              column-gap: var(--dist-1); */}
              padding-left: calc(
                2 * var(--global-column) + 2 * var(--global-gutter) +
                  var(--dist-1)
              );
              padding-right: calc(
                2 * var(--global-column) + 2 * var(--global-gutter) +
                  var(--dist-1)
              );
              max-width: calc(
                8 * var(--global-column) + 7 * var(--global-gutter) +
                  var(--dist-1)
              );
            }

            & > .BlockText[data-type="small"] {
              padding-left: calc(
                2 * var(--global-column) + 2 * var(--global-gutter) +
                  var(--dist-1)
              );
              padding-right: calc(
                2 * var(--global-column) + 2 * var(--global-gutter) +
                  var(--dist-1)
              );
            }

            & > .BlockText[data-type="large"] {
              padding-left: calc(
                2 * var(--global-column) + 2 * var(--global-gutter) +
                  var(--dist-1)
              );
            }
          }
        }
      `}</style>
    </>
  );
};

export default BlockText;
